// Base URLs
const BASE = {
  CASINO: '/livecasino',
  ACCOUNT: '/account',
  GUIDE: '/huong-dan',
  PROMOTION: '/khuyen-mai',
  SPORT: '/the-thao',
  NEWS: '/tin-tuc',
  GAME: '/game',
  INTRODUCTION: '/gioi-thieu',
  MAINTAIN: '/maintain'
}

// Page URLs
export const PAGE_URLS = {
  HOME: '/',
  INTRODUCTION: BASE.INTRODUCTION,
  NEWS: BASE.NEWS,
  NEWS_CATEGORY: `${BASE.NEWS}/the-loai`,
  GUIDE: BASE.GUIDE,
  PROMOTION: `${BASE.PROMOTION}`,
  PROMOTION_VIP: `${BASE.PROMOTION}/vip`,
  PROMOTION_CATEGORY: `${BASE.PROMOTION}/the-loai`,
  PROMOTION_DETAIL: `${BASE.PROMOTION}/detail`,
  GAME: BASE.GAME,
  CASINO: BASE.CASINO,
  SPORT: BASE.SPORT,
  ACCOUNT: BASE.ACCOUNT,
  MAINTAIN: '/maintenence'
}

export const ACCOUNT_URLS = {
  INDEX: BASE.ACCOUNT,
  PROFILE: `${BASE.ACCOUNT}/profile`,
  BANK: `${BASE.ACCOUNT}/bank`,
  INFO_USER: `${BASE.ACCOUNT}/information/user`,
  ADD_BANK: `${BASE.ACCOUNT}/information/bank?currentTab=ADD_BANK`,
  DEPOSIT: `${BASE.ACCOUNT}/deposit`,
  DEPOSIT_TA_PAY: `${BASE.ACCOUNT}/deposit/ta-pay`,
  DEPOSIT_DA: `${BASE.ACCOUNT}/deposit/bank?type=codepay`,
  DEPOSIT_BANK: `${BASE.ACCOUNT}/deposit/bank`,
  DEPOSIT_CODEPAY2: `${BASE.ACCOUNT}/deposit/bank?type=codepay2`,
  DEPOSIT_MOMO: `${BASE.ACCOUNT}/deposit/ewallet?type=momo`,
  DEPOSIT_VIETTEL_PAY: `${BASE.ACCOUNT}/deposit/ewallet?type=viettelpay`,
  DEPOSIT_ZALO_PAY: `${BASE.ACCOUNT}/deposit/ewallet?type=zalopay`,
  DEPOSIT_CARD: `${BASE.ACCOUNT}/deposit/card`,
  P2P: `${BASE.ACCOUNT}/trading-p2p`,
  DEPOSIT_CRYPTO: `${BASE.ACCOUNT}/deposit/crypto`,
  WITHDRAW: `${BASE.ACCOUNT}/withdraw`,
  WITHDRAW_BANK: `${BASE.ACCOUNT}/withdraw/bank`,
  WITHDRAW_CRYPTO: `${BASE.ACCOUNT}/withdraw/crypto`,
  WITHDRAW_CARD: `${BASE.ACCOUNT}/withdraw/card`,
  TRANSACTION_HISTORY: `${BASE.ACCOUNT}/history/transaction`,
  TRANSACTION_P2P: `${BASE.ACCOUNT}/trading-p2p`,
  BET_HISTORY: `${BASE.ACCOUNT}/history/bet`,
  PROFIT_TODAY: `${BASE.ACCOUNT}/profit-today`
}

// Lobby Game URLs
export const LOBBY_GAME_URLS = {
  ALL: BASE.GAME,
  LO_DE: `${BASE.GAME}/lo-de`,
  BAN_CA: `${BASE.GAME}/ban-ca`,
  QUAY_SO: `${BASE.GAME}/quay-so`,
  TABLE_GAME: `${BASE.GAME}/table-game`,
  GAME_BAI: `${BASE.GAME}/game-bai`,
  SLOTS_GAME: `${BASE.GAME}/slots`,
  NO_HU: `${BASE.GAME}/no-hu`,
  INGAME: `${BASE.GAME}/ingame`,
  QUICK_GAMES: `${BASE.GAME}/game-nhanh`,
  V_GAMING: `${BASE.GAME}/v-gaming`,
  LIVE_STREAM: `${BASE.GAME}/livestream`
}

// Lobby Casino URLs
export const LOBBY_CASINO_URLS = {
  ALL: BASE.CASINO,
  ALL_CASINO: `${BASE.CASINO}/all`,
  TAIXIU: `${BASE.CASINO}/taixiu`,
  XOCDIA: `${BASE.CASINO}/xocdia`,
  BAUCUA: `${BASE.CASINO}/baucua`,
  ROULETTE: `${BASE.CASINO}/roulette`,
  BACCARAT: `${BASE.CASINO}/baccarat`,
  POKER: `${BASE.CASINO}/poker`,
  SICBO: `${BASE.CASINO}/sicbo`,
  BLACKJACK: `${BASE.CASINO}/blackjack`,
  DRAGONTIGER: `${BASE.CASINO}/dragontiger`,
  OTHERS: `${BASE.CASINO}/others`,
  LIVESTREAM: `${BASE.CASINO}/livestream`,
  CASINO_RIK: `${BASE.CASINO}/all?partner=rik`,
  CASINO_EVO: `${BASE.CASINO}/all?partner=evo`,
  CASINO_GO88: `${BASE.CASINO}/all?partner=go`,
  CASINO_PRAMATIC: `${BASE.CASINO}/all?partner=pragmatic`,
  CASINO_VIVO: `${BASE.CASINO}/all?partner=vivo`
}

export const NCC_TYPES = {
  KSPORTS: 'ksports',
  CSPORTS: 'csports',
  SSPORTS: 'saba-sports',
  MSPORTS: 'msports',
  TSPORTS: 'tsports',
  VIRTUAL_SPORTS: 'the-thao-ao',
  ESPORTS: 'the-thao-dien-tu',
  PSPORTS: 'psports',
  ASPORTS: 'asports',
  ISPORTS: 'isports'
}

// Iframe Game URLs
export const SPORTS_URLS = {
  INDEX: BASE.SPORT,
  SCHEDULES: `${BASE.SPORT}/lich-thi-dau`,
  K_SPORT: `${BASE.SPORT}/${NCC_TYPES.KSPORTS}`,
  C_SPORT: `${BASE.SPORT}/${NCC_TYPES.CSPORTS}`,
  A_SPORT: `${BASE.SPORT}/${NCC_TYPES.ASPORTS}`,
  S_SPORT: `${BASE.SPORT}/${NCC_TYPES.SSPORTS}`,
  P_SPORT: `${BASE.SPORT}/${NCC_TYPES.PSPORTS}`,
  M_SPORT: `${BASE.SPORT}/${NCC_TYPES.MSPORTS}`,
  E_SPORT: `${BASE.SPORT}/${NCC_TYPES.ESPORTS}`,
  T_SPORT: `${BASE.SPORT}/${NCC_TYPES.TSPORTS}`,
  I_SPORT: `${BASE.SPORT}/${NCC_TYPES.ISPORTS}`,
  VIRTUAL_SPORTS: `${BASE.SPORT}/${NCC_TYPES.VIRTUAL_SPORTS}`
}

export const PROMOTION_URLS = {
  olympic_2024: `${BASE.PROMOTION}/gio-vang-dat-cuoc-bong-da-tai-da88-thuong-100K`,
  promotion_158: `${BASE.PROMOTION}/hoan-tra-the-thao-158-moi-ngay-tai-da88`,
  refund: `${BASE.PROMOTION}/hoan-tien-ve-thua-dau-ngay-1-trieu-tai-da88`,
  promotion_05: `${BASE.PROMOTION}/can-quet-game-bai-doi-thuong-da88-hoan-tra-bat-tan`,
  funding: `${BASE.PROMOTION}/da88-cap-von-3-lan-tien-nap`,
  euro2024: `${BASE.PROMOTION}/cuoc-bong-da-euro-nhan-thuong-bat-ngo-tu-da88`,
  copa2024: `${BASE.PROMOTION}/thuong-100K-cuoc-bong-da-copa-america-tai-da88-moi-ngay`
}
