import { defineStore, acceptHMRUpdate } from 'pinia'
import COOKIES_DEVICE from './cookies-device'
import { ICategory } from '~/types/news'
import { ISEO } from '~/types/seo'
import { IBreadcrumbItem } from '~/types/common'
import { ICategoryPromotion } from '~/types/promotion'

interface AppState {
  currentUser: {
    tp_token?: string
    [key: string]: any
  } | null
}

export const useAppStore = defineStore('app', {
  state: (): {
    jackpot: any
    sumJackpot: any
    currentUser: any
    newsCategories: ICategory[]
    promotionCategories: ICategoryPromotion[],
    seoData: ISEO[],
    transactionDetail: any
    refreshInterval: any
    currentUserRank: number
    openDepositFrom: string
    openWithdrawFrom: string
    gameItem: Object,
    isOutFullScreen: boolean,
    plan: any
  } => ({
    currentUser: null,
    jackpot: {},
    sumJackpot: {},
    newsCategories: [],
    promotionCategories: [],
    seoData: [],
    transactionDetail: null,
    refreshInterval: null,
    currentUserRank: 1,
    gameItem: {},
    openDepositFrom: '',
    openWithdrawFrom: '',
    plan: null,
    isOutFullScreen: true
  }),
  getters: {
    isLogged: (state): boolean => !!state.currentUser,
    getToken: (state): string | undefined => state.currentUser?.tp_token
  },
  actions: {
    setJackpot(jackpot: any) {
      this.jackpot = jackpot
    },
    setSumJackpot(sumJackpot: any) {
      this.sumJackpot = sumJackpot
    },
    setRefreshInterval(payload: null) {
      this.refreshInterval = payload
    },
    mutate({ property, with: newValue }: { property: keyof AppState; with: any }) {
      this.$state[property] =
        typeof newValue === 'object' && newValue !== null ? { ...this.$state[property], ...newValue } : newValue
    },
    setCurrentUser(user: any) {
      this.currentUser = user ? { ...user } : null
    },
    setCurrentUserRank(payload: any) {
      this.currentUserRank = payload
    },
    setGameItem(payload: Object) {
      this.gameItem = payload
    },
    setDepositFrom(payload: string) {
      this.openDepositFrom = payload
    },
    setOpenWithdrawFrom(payload: string) {
      this.openWithdrawFrom = payload
    },
    updateCurrentUser(updates: any) {
      if (updates) {
        this.currentUser = { ...this.currentUser, ...updates }
      } else {
        this.currentUser = null
      }
    },
    updateTransactionDetail(payload: any) {
      this.transactionDetail = payload
    },
    setNewsCategories(cat: ICategory[]) {
      this.newsCategories = cat ?? []
    },
    setPromotionCategories(cat: ICategoryPromotion[]) {
      this.promotionCategories = cat ?? []
    },
    setSeoData(data: ISEO[]) {
      this.seoData = data ?? []
    },
    updatePlan(payload: any) {
      this.plan = payload
    },
    setIsOutFullScreen(payload: boolean) {
      this.isOutFullScreen = payload
    },
    ...COOKIES_DEVICE
  },
  persist: true
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
}
